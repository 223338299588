import { chakra } from '@chakra-ui/react'

export const GovtMastheadIcon = chakra(
  (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        width="1em"
        height="1em"
        fill="#FF824A"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        {...props}
      >
        <path d="M 18.101562 15.679688 L 17.019531 15.679688 L 17.019531 8.378906 L 18.101562 8.378906 L 18.101562 6.699219 L 9.980469 2.039062 L 1.898438 6.699219 L 1.898438 8.378906 L 2.980469 8.378906 L 2.980469 15.679688 L 1.898438 15.679688 L 1.898438 16.621094 L 1 16.621094 L 1 17.980469 L 19 17.980469 L 19 16.621094 L 18.101562 16.621094 Z M 14.28125 6.699219 L 5.738281 6.699219 L 9.980469 4.300781 Z M 4.5 15.679688 L 4.5 9.339844 L 7.199219 9.339844 L 7.199219 15.679688 Z M 8.660156 15.679688 L 8.660156 9.339844 L 11.359375 9.339844 L 11.359375 15.679688 Z M 12.839844 15.679688 L 12.839844 9.339844 L 15.539062 9.339844 L 15.539062 15.679688 Z M 12.839844 15.679688 " />
      </svg>
    )
  },
)
